import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";

function RedirectPage() {
  const [seconds, setSeconds] = useState(6);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (seconds === 0) {
      setRedirect(true);
      window.location.href = "https://www.ghanabar.org";
    } else {
      const timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [seconds]);

  return (
    <div className="flex flex-col text-center mt-20">
      <Typography variant="h3">Please access the Portal page</Typography>
      <Typography variant="h3">from the main Ghana Bar website</Typography>
      <Typography
        variant="h3"
        as="a"
        href="https://www.ghanabar.org"
        rel="noopener noreferrer"
        color="pink"
      >
        www.ghanabar.org
      </Typography>
      <Typography className="my-10">
        {redirect
          ? "Redirecting..."
          : `Page will redirect in ${seconds} seconds`}
      </Typography>
    </div>
  );
}

export default RedirectPage;
