import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { forgortPassword } from "../../redux/actions/auth_action";
import { Button, Card, Spinner, Typography } from "@material-tailwind/react";
import InputText from "../../common/forms/InputText";
import AlertMessage from "../../common/actions/AlertMessage";
import { FORGOT_DATA } from "../../constants/constants";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState(FORGOT_DATA);

  const dispatch = useDispatch();

  const forgotError = useSelector((state) => state.auth?.forgotError);
  const token = Math.floor(Date.now() / 1000) + 120;

  const updateFormValue = ({ updateType, value }) => {
    setEmailData({ ...emailData, [updateType]: value });
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(forgortPassword({ email: emailData.email })).finally(() =>
      setLoading(false)
    );
  };
  return (
    <div className="min-h-screen flex items-center">
      <Card className="mx-auto w-full max-w-xl shadow-xl">
        <div className="py-24 px-10">
          <div className="text-center">
            <img src="./gba-logo.png" alt="gba logo" className="inline-block" />
            <Typography color="pink" variant="h5" className="mt-8 mb-4">
              You may call the Bar Center on +233 (0302) 912977 / +233 (0302)
              910650 for assistance
            </Typography>
            <div className="flex items-center">
              <div className="flex-1 border-t-2 border-gray-200"></div>
              <span className="px-3 text-gray-500 bg-white">OR</span>
              <div className="flex-1 border-t-2 border-gray-200"></div>
            </div>
            <Typography color="black" variant="h5" className="my-2">
              Enter Email to Reset Password
            </Typography>
          </div>
          <form onSubmit={(e) => handleForgot(e)}>
            <div className="mb-16 mt-12">
              {forgotError && (
                <AlertMessage type="error" message={forgotError} />
              )}

              <InputText
                type="text"
                defaultValue={emailData.email}
                updateType="email"
                containerStyle="mt-4"
                labelTitle="Email Address"
                updateFormValue={updateFormValue}
                required={true}
              />
            </div>
            <Button
              variant="filled"
              className="text-center flex items-center justify-center"
              size="lg"
              fullWidth={true}
              type="submit"
            >
              {loading ? <Spinner color="white" /> : "Forgot Password"}
            </Button>

            <div className="mt-4 text-center">
              <Link to={`/?gba=${token}`}>
                <Typography variant="small" color="pink">
                  Remembered Password? Login Here
                </Typography>
              </Link>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default ForgotPassword;
