import React, { useEffect, useState } from "react";
import { LOGIN_DATA } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Spinner, Typography } from "@material-tailwind/react";
import { signInAction } from "../../redux/actions/auth_action";
import AlertMessage from "../../common/actions/AlertMessage";
import InputText from "../../common/forms/InputText";
import InputPassword from "../../common/forms/InputPassword";
import RedirectPage from "./RedirectPage";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState(LOGIN_DATA);

  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signInError = useSelector((state) => state.auth?.signInError);

  const token = searchParams.get("gba");
  const currentSeconds = Math.floor(Date.now() / 1000);

  const timeDifference = token - currentSeconds;

  const updateFormValue = ({ updateType, value }) => {
    setLoginData({ ...loginData, [updateType]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(signInAction(loginData, navigate)).finally(() =>
      setLoading(false)
    );
  };

  useEffect(() => {
    if (!id) {
      if (token) {
        if (currentSeconds < token && timeDifference < 121) {
          setId(true);
        } else {
          setId(false);
        }
      } else {
        setId(false);
      }
    }
  }, [token, currentSeconds, id, timeDifference]);

  if (!id) return <RedirectPage />;

  if (id)
    return (
      <div className="min-h-screen flex items-center">
        <Card className="mx-auto w-full max-w-xl shadow-xl">
          <div className="py-15 px-10">
            <div className="text-center">
              <img
                src="./gba-logo.png"
                alt="gba logo"
                className="inline-block"
              />
              <Typography color="black" variant="h4" className="mb-2 mt-8">
                Login To Account
              </Typography>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-12 mt-12">
                {signInError && (
                  <AlertMessage type="error" message={signInError} />
                )}

                <InputText
                  type="text"
                  defaultValue={loginData.username}
                  updateType="username"
                  containerStyle="mt-4"
                  labelTitle="Username"
                  updateFormValue={updateFormValue}
                  required={true}
                />

                <InputPassword
                  defaultValue={loginData.password}
                  updateType="password"
                  containerStyle="mt-4"
                  labelTitle="Password"
                  updateFormValue={updateFormValue}
                  required={true}
                />

                <div className="text-right mt-2">
                  <Link to="/forgot">
                    <Typography variant="small" color="pink">
                      Forgot Password
                    </Typography>
                  </Link>
                </div>
              </div>

              <Button
                variant="filled"
                className="text-center flex items-center justify-center"
                size="lg"
                fullWidth={true}
                type="submit"
              >
                {loading ? <Spinner color="white" /> : "Login"}
              </Button>

              <div className="mt-4 text-center">
                <Link to="/signup">
                  <Typography variant="paragraph" color="pink">
                    Dont have an account? Register Here
                  </Typography>
                </Link>
              </div>

              <div className="mt-4 text-center">
                <Typography className="mt-4 poli-sm">
                  <span className="font-bold">DISCLAIMER:</span> As fully
                  permitted by law, the Ghana Bar Association (GBA) provides
                  this portal and website, together with its contents on "as is"
                  basis. The GBA disclaims all representations, assumptions or
                  warranties, express or implied, with respect to this portal
                  and website or the information, content, products, output,
                  services, references or linkages included in this portal and
                  website including warranties of merchantability and fitness
                  for any purpose. The GBA makes no representation or warrant of
                  any kind that the information retrievable or accessible via
                  this portal and website is accurate, complete, up-to-date or
                  current. Under no circumstances, as fully permitted by law,
                  shall the GBA or any of its officers, hires, contractors,
                  agents or employees be liable for any loss, injury, pain, cost
                  or damage howsoever incurred or arising suffered as a result
                  of any use of or interaction with this portal and website or
                  its information, content, products, output, services,
                  references or linkages
                </Typography>

                <Typography className="mt-2 poli-sm">
                  `This portal and website was developed by the Ghana Bar
                  Association Copyright © 2018 - {new Date().getFullYear()} –
                  Ghana Bar Association. All rights reserved. Your mere visit or
                  use of this portal and website binds you in a browse-wrap
                  contract in our{" "}
                  <span className="text-pink-600">
                    <Link to={"/terms"}>
                      Terms of Use which are available by clicking here
                    </Link>
                  </span>
                  `
                </Typography>

                <Typography className="my-2 poli-sm">
                  ` You may read our Privacy Policy by{" "}
                  <span className="text-pink-600">
                    <Link to={"/policy"}>clicking here.</Link>
                  </span>
                  `
                </Typography>
              </div>
            </form>
          </div>
        </Card>
      </div>
    );
}

export default SignIn;
