import React from "react";
import ReceiptButton from "../../../common/buttons/ReceiptButton";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import LicenseMakePaymentButton from "./LicenseMakePaymentButton";
import ClearedButton from "./ClearedButton";

function LicenseButtons({ data }) {
  const navigate = useNavigate();

  // const userData = JSON.parse(localStorage.getItem("profile"));

  const checkYear = new Date().getFullYear() - 1;

  const isPaid = data && data?.receipts.find((item) => item.status === 1);

  const formData = {
    year: data?.year,
    cert_number: isPaid?.cert_number,
    date_paid: isPaid?.date_paid,
  };

  const handleCertGenerate = () => {
    navigate("/home/license/cert", { state: { formData } });
  };

  return isPaid ? (
    <div className="flex items-center gap-3 justify-between">
      <ReceiptButton
        disabled={
          !data?.standing ||
          !data?.pupilStatus ||
          (!data?.previousReceipt &&
            !data?.previousManual &&
            !data?.manualGreen &&
            data?.length !== 1) ||
          (!data?.currentReceipt && data?.length !== 1) ||
          (!data?.isRegion && parseInt(data?.year) >= 2024)
        }
        id={isPaid?.id}
        text="View Receipt"
        link={`/home/license/receipt/${isPaid?.id}`}
      />

      <Button
        disabled={
          !data?.standing ||
          !data?.pupilStatus ||
          (!data?.previousReceipt &&
            !data?.previousManual &&
            !data?.manualGreen &&
            data?.length !== 1) ||
          (!data?.currentReceipt && data?.length !== 1) ||
          (!data?.isRegion && parseInt(data?.year) >= 2024)
        }
        color="indigo"
        fullWidth
        onClick={() => handleCertGenerate()}
      >
        View Cert
      </Button>
    </div>
  ) : data.standing &&
    data.isRegion &&
    ((data.index === 0 && (data.previousReceipt || data.previousManual)) ||
      (data.index === 0 && data.p_date >= checkYear) ||
      (data.index === 0 && data.passManual)) ? (
    <LicenseMakePaymentButton data={data} />
  ) : data.index > 0 ? (
    <ClearedButton manual={data.manualGreen} />
  ) : null;

  // return data?.receipts[0]?.status === 1 ? (
  //   <div className="flex items-center gap-3 justify-between">
  //     <ReceiptButton
  //       disabled={
  //         !userData?.user?.standing ||
  //         !data.pupilStatus ||
  //         !data.manualGreen ||
  //         (!data.isRegion && parseInt(data.year) >= 2024)
  //       }
  //       id={data?.receipt?.id}
  //       text="View Receipt"
  //       link={`/home/license/receipt/${data?.receipts[0]?.id}`}
  //     />
  //     <Button
  //       disabled={
  //         !userData?.user?.standing ||
  //         !data.pupilStatus ||
  //         !data.manualGreen ||
  //         (!data.isRegion && parseInt(data.year) >= 2024)
  //       }
  //       color="indigo"
  //       fullWidth
  //       onClick={() => handleCertGenerate()}
  //     >
  //       View Cert
  //     </Button>
  //   </div>
  // ) : data.index === 0 && (data.previousReceipt || data.manualGreen) ? (
  //   <LicenseMakePaymentButton data={data} />
  // ) : data.index === 0 && data.p_date >= checkYear ? (
  //   <LicenseMakePaymentButton data={data} />
  // ) : data.index > 0 ? (
  //   <ClearedButton manual={data.currentManual} />
  // ) : null;
  // ) : (
  //   <LicenseMakePaymentButton data={data} disable={true} />
  // );
}

export default LicenseButtons;
